import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { HOME_TIMELINE_VIEW } from '../../../../hooks/useSelectProject';
import TooltipWrapper from '../../../TooltipWrapper';

const handleProjectClick = (gantt, project, onHideTooltip) => () => {
  if (!gantt.processedProjects[project.id]) {
    return;
  }

  onHideTooltip();

  gantt.onClickProject(
    gantt.processedProjects[project.id],
    HOME_TIMELINE_VIEW,
    gantt.analytics
  );
};

const shouldShowTooltip = ({ scrollWidth, clientWidth }) => {
  return scrollWidth > clientWidth;
};

const ProjectCell = ({ gantt, project }) => {
  const projectNameRef = useRef(null);
  const scheduleNameRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState({
    project: false,
    schedule: false
  });

  const hideTooltips = () => {
    setShowTooltip({
      project: false,
      schedule: false
    });
  };

  const updateTooltipVisibility = () => {
    if (!projectNameRef.current || !scheduleNameRef.current) return;

    setShowTooltip({
      project: shouldShowTooltip(projectNameRef.current),
      schedule: shouldShowTooltip(scheduleNameRef.current)
    });
  };

  const handleCellClick = (event) => {
    const tooltipElement = event.target.closest('.tooltip-design-system-v2');
    if (tooltipElement) {
      return;
    }

    hideTooltips();
    setTimeout(updateTooltipVisibility, 0);
  };

  useEffect(() => {
    updateTooltipVisibility();
  }, [project]);

  const options = [
    {
      key: 'project',
      ref: projectNameRef,
      className: 'project-name',
      text: project.name
    },
    {
      key: 'schedule',
      ref: scheduleNameRef,
      className: 'main-schedule',
      onClick: handleProjectClick(gantt, project, hideTooltips),
      text: project.scheduleName
    }
  ];

  return (
    <div className="project-cell" onClick={handleCellClick}>
      {options.map(({ key, text, ...props }) => (
        <TooltipWrapper key={key} title={text} shouldWrap={showTooltip[key]}>
          <span key={key} {...props}>
            {text}
          </span>
        </TooltipWrapper>
      ))}
    </div>
  );
};

export default ProjectCell;
