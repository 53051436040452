/* eslint-disable no-magic-numbers */
import PropTypes from 'prop-types';
import React from 'react';
import { getCompanyTextColor } from '../../../../utils';
import TooltipSystemV2 from '../../../DesignSystem/TooltipSystemV2';
import './styles.scss';

const CompanyCell = ({ name, color }) => (
  <div
    className="company-cell"
    style={{ backgroundColor: color, color: getCompanyTextColor(color) }}>
    <TooltipSystemV2 placement="top" title={name}>
      <span>{name}</span>
    </TooltipSystemV2>
  </div>
);

CompanyCell.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default CompanyCell;
