import { trackingEvent } from '../../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../../../analytics/utils';
import {
  buildBaseLine,
  getUidTask,
  parseActualFinish,
  parseActualStart,
  parseConstraintType,
  parseDate,
  parseDurationHoursToDays,
  parseFreeSlackHoursToDays,
  parseTotalSlackHoursToDays,
  validateMilestone
} from './helpers';

const getAutodeskTasks = (gantt) => ({
  FreeSlack: (task) => parseFreeSlackHoursToDays(gantt, task),
  TotalSlack: (task) => parseTotalSlackHoursToDays(gantt, task),
  Duration: (task) => parseDurationHoursToDays(gantt, task),
  ConstraintDate: (task) => parseDate(gantt, task.id),
  ConstraintType: (task) => parseConstraintType(gantt, task.id),
  Baseline: (task) => buildBaseLine(gantt, task),
  Work: (task) => task.hhWorkTime,
  RemainingWork: (task) => task.hhWorkTime,
  Cost: (task) => (gantt.hasChild(task.id) ? 0 : task.cost),
  FixedCost: (task) => (gantt.hasChild(task.id) ? 0 : task.cost),
  RemainingCost: (task) => (gantt.hasChild(task.id) ? 0 : task.cost),
  DurationFormat: (_) => 7,
  Milestone: (task) => validateMilestone(task),
  ActualStart: (task) => parseActualStart(task),
  ActualFinish: (task) => parseActualFinish(task),
  ExclusiveID: (task) => getUidTask(task),
  Notes: (task) => task.proplannerId,
  SubprojectName: (task) => `${task.calendar_id}`,
  Contact: (task) => getUidTask(task)
});

export const autodeskParseData = (gantt) => {
  trackingEvent(
    'autodesk_xml_exportable_generation',
    { ...getBasicAmplitudEventProperties() },
    AMPLITUDE_SERVICE
  );

  const data = getAutodeskTasks(gantt);
  return data;
};
