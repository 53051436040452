import { activityService } from '../../../../services';

export const scheduleUpdateProcore = async ({ gantt, exportProps }) => {
  const xmlData = await gantt.exportProcorexml();
  if (!xmlData) {
    return;
  }

  const xmlResponse = await activityService.exportToXML({
    ...exportProps,
    data: JSON.stringify([xmlData])
  });

  return xmlResponse;
};
