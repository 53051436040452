import { sentryLogger } from '../../../monitor/monitor';
import { scheduleUpdatesService } from '../../../services';
import {
  getCurrentSector,
  getSessionTokenData,
  getSignedUser
} from '../../../utils/userUtils';
import notificationSystemV2 from '../../DesignSystem/NotificationSystemV2/NotificationSystemV2';
import { scheduleUpdateAutodesk } from './Autodesk';
import { scheduleUpdateProcore } from './Procore';

export const saveAllScheduleUpdate = async ({
  t,
  gantt,
  projectState,
  companySettingsState,
  saveScheduleUpdatesModal,
  sectorAutodeskAssignation,
  handleConfigClick
}) => {
  try {
    const loggedUser = getSignedUser();
    const sessionTokenData = getSessionTokenData();
    const sector = getCurrentSector();

    if (!loggedUser || !sessionTokenData || !sector) {
      return {};
    }

    const exportProps = {
      project_id: sector.projectId,
      sector_id: sector.id,
      company_id: loggedUser.companyId
    };

    const xmlProcore = await scheduleUpdateProcore({
      gantt,
      exportProps
    });

    const xmlAutodesk = await scheduleUpdateAutodesk({
      gantt,
      projectState,
      companySettingsState,
      sectorAutodeskAssignation,
      exportProps,
      t
    });

    const newScheduleUpdate = {
      name: saveScheduleUpdatesModal.update_name,
      comment: saveScheduleUpdatesModal.comment,
      userId: loggedUser.id,
      sectorId: sector.id,
      has_procore: xmlProcore.isprocore || false,
      xmlUrl: xmlProcore.url,
      has_autodesk: Boolean(xmlAutodesk?.url),
      xmlUrlAutodesk: xmlAutodesk.url
    };

    await scheduleUpdatesService.addScheduleUpdate(newScheduleUpdate);

    handleConfigClick('update_schedule_updates');
    handleConfigClick('show_schedule_updates');
    notificationSystemV2({
      type: 'success',
      key: 'schedule-push-success',
      message: t('schedule_push.success.title'),
      description: t('schedule_push.success.description')
    });

    return {
      xmlUrlProcore: xmlProcore.url,
      xmlUrlAutodesk: xmlAutodesk.url
    };
  } catch (error) {
    sentryLogger.error('saveScheduleUpdate', {
      error
    });
  }
};
