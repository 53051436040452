import moment from 'moment';
import { earlyAccessCriticalPath } from '../../../../../utils/earlyAccessCriticalPath';
import { CONSTRAINT_TYPE_MAP, FORMAT_DATE } from './constants';

export const convertPredessesors = (gantt, link) =>
  extractNumber(formaterDays(link.lag, gantt), true);

const getBaseLine = (task) => {
  if (task.baseline_points && task.baseline_points.length) {
    return task.baseline_points.find(
      (base) => base.sectorbaselineversion && base.sectorbaselineversion.active
    );
  }

  return null;
};

const searchTaskCalendarInfo = (tasks, gantt, iterator = 0) => {
  if (tasks.length <= iterator) return null;

  if (tasks[iterator].calendar_id) {
    const calendar = gantt.calendarOptions.find(
      (calendar) => calendar.key == tasks[iterator].calendar_id
    );

    if (calendar) return calendar;
  }

  searchTaskCalendarInfo(tasks, gantt, iterator + 1);
};

const isNegative = (value) => value < 0;

const extractNumber = (value, int = true) => {
  const num = value.split(' ').shift();
  if (isNaN(num)) return 1;
  const res = int ? parseInt(num) : parseFloat(num);

  return num >= 1 ? res : parseFloat(num).toFixed(2);
};

const formaterDays = (value, gantt) =>
  value ? gantt.formatter.format(Math.abs(value)) : '0 days';

export const getCalendarName = (gantt, tasks) => {
  const calendar = searchTaskCalendarInfo(tasks, gantt);
  return calendar?.label || 'Custom';
};

export const parseConstraintType = (gantt, taskId) => {
  if (!gantt || !taskId) return CONSTRAINT_TYPE_MAP.asap;

  const task = gantt.getTask(taskId);
  const { progress, $target, constraint_type, type } = task;

  const PROJECT = 'project';
  const ASAP = 'asap';
  const asap = gantt.config.constraint_types.ASAP || ASAP;
  const SNET_CODE = 4;

  const isNotProject = type !== PROJECT;
  const isProgressNull = progress === 0;
  const hasPredecessors = $target && $target.length !== 0;
  const isASAP = constraint_type === asap;

  const isChangingConstraintType =
    isNotProject && isProgressNull && !hasPredecessors && isASAP;

  return isChangingConstraintType
    ? SNET_CODE
    : CONSTRAINT_TYPE_MAP[constraint_type];
};

export const parseDurationHoursToDays = (gantt, task) => {
  const duration =
    task.for_disable_milestone_duration !== 0
      ? gantt.formatter.format(task.for_disable_milestone_duration)
      : '0 days';
  return extractNumber(duration, false);
};

export const buildBaseLine = (gantt, task) => {
  const baseline = getBaseLine(task);

  if (!baseline) {
    return {};
  }

  const startBase = baseline.start_date.replace(/\//g, '-').split(/[-|\s]/);
  const endBase = baseline.end_date.replace(/\//g, '-').split(/[-|\s]/);

  task.start_base = `${startBase[2]}-${startBase[1]}-${startBase[0]} ${startBase[3]}`;
  task.end_base = `${endBase[2]}-${endBase[1]}-${endBase[0]} ${endBase[3]}`;
  task.work_base = baseline.hh_work;
  task.cost_base = baseline.cost;
  const hoursPerDay = gantt.config.hoursPerDay;

  return {
    Start: moment.utc(task.start_base, FORMAT_DATE),
    Finish: moment.utc(task.end_base, FORMAT_DATE),
    Duration: baseline.duration,
    DurationFormat: 7,
    Work: task.work_base / hoursPerDay,
    Cost: baseline.cost
  };
};

export const parseTotalSlackHoursToDays = (gantt, task) => {
  const TOTAL_SLACK_DEFAULT = 0;

  const hoursPerDay = gantt.config.hoursPerDay;
  const totalSlack = task.totalSlack || TOTAL_SLACK_DEFAULT;
  const negative = isNegative(totalSlack);

  if (negative) {
    return hoursPerDay * -Math.abs(totalSlack);
  }

  return totalSlack * hoursPerDay;
};

export const parseFreeSlackHoursToDays = (gantt, task) => {
  const freeSlack = task.freeSlack || 0;
  const hoursPerDay = gantt.config.hoursPerDay;

  const negative = isNegative(freeSlack);

  if (negative) {
    return hoursPerDay * -Math.abs(freeSlack);
  }

  return freeSlack * hoursPerDay;
};

export const parseDate = (gantt, taskId) => {
  if (!gantt || !taskId) return null;

  const getFormat = (date) => {
    const dateFormat = moment(date).format(FORMAT_DATE);
    return moment.utc(dateFormat, FORMAT_DATE);
  };

  const constraintTypes = gantt && gantt.config.constraint_types;
  if (!constraintTypes) return null;

  const task = gantt.getTask(taskId);
  const { progress, $target, constraint_date, constraint_type, start_date } =
    task;

  const hasPredecessors = $target && $target.length !== 0;

  const isProgressNull = progress === 0;
  const isASAP = constraint_type === constraintTypes.ASAP;
  const isALAP = constraint_type === constraintTypes.ALAP;

  if (isProgressNull && !hasPredecessors && isASAP) {
    return getFormat(start_date);
  }

  if (!isASAP && !isALAP) {
    return getFormat(constraint_date);
  }

  return null;
};

export const validateMilestone = (task) =>
  task.duration === 0 || task.type === 'milestone' ? 1 : 0;

export const parseActualStart = (task) => {
  let actualStart = moment.utc(task.start_date, FORMAT_DATE);
  if (task.progress === 0) actualStart = '01-01-1000T00:00:00';
  return moment.utc(actualStart, FORMAT_DATE);
};

export const parseActualFinish = (task) => {
  let actualFinish = moment.utc(task.end_date, FORMAT_DATE);
  if (task.progress !== 100) actualFinish = '01-01-1000T00:00:00';
  return moment.utc(actualFinish, FORMAT_DATE);
};

export const responsibleDataProcore = (gantt) => {
  const tasks = gantt.getTaskByTime();
  const responsibles = [];
  const assignments = [];

  tasks.forEach((task) => {
    const { responsables, proplannerId: task_id } = task;
    responsables &&
      responsables.forEach((responsible) => {
        const { id: responsible_id } = responsible;
        const isDuplicateResponsible = responsibles.some(
          (respo) => respo.id === responsible_id
        );

        if (!isDuplicateResponsible) responsibles.push(responsible);
        const assignment = {
          taskId: task_id,
          responsibleId: responsible_id
        };
        assignments.push(assignment);
      });
  });
  return { responsibles, assignments };
};

export const getTaskByGanttFormatProcore = (gantt) => {
  const getTask = gantt.getTaskByTime();
  const tasks = {};

  getTask.forEach((task) => {
    const { proplannerId, start_date, end_date } = task;
    if (!proplannerId) return;

    const start_task = moment(start_date)
      .format('YYYY-MM-DDTHH:mm:ss')
      .toString();
    const end_task = moment(end_date).format('YYYY-MM-DDTHH:mm:ss').toString();
    tasks[proplannerId] = { ...task, start_task, end_task };
  });
  return tasks;
};

export const getUidTask = (task) => {
  return `${Number(task.unique_correlative_id) + 1}`;
};
