import { activityService } from '../../../../services';
import notificationSystemV2 from '../../../DesignSystem/NotificationSystemV2/NotificationSystemV2';
import { exportAutodeskXml } from './ExportXml';

const AUTODESK_PROJECT_NOT_EXIST = 'autodesk_project_dont_exist';
const AUTODESK_SCHEDULE_NOT_EXIST = 'autodesk_schedule_dont_exist';

const notificationError = ({ message, t }) => {
  if (message === AUTODESK_PROJECT_NOT_EXIST) {
    notificationSystemV2({
      type: 'error',
      key: 'autodesk-project-not-found',
      message: t('schedule_push.project_not_found.title'),
      description: t('schedule_push.project_not_found.description')
    });
    return;
  }

  if (message === AUTODESK_SCHEDULE_NOT_EXIST) {
    notificationSystemV2({
      type: 'error',
      key: 'autodesk-schedule-not-found',
      message: t('schedule_push.schedule_not_found.title'),
      description: t('schedule_push.schedule_not_found.description')
    });
  }
};

export const scheduleUpdateAutodesk = async ({
  gantt,
  projectState,
  companySettingsState,
  sectorAutodeskAssignation,
  exportProps,
  t
}) => {
  if (!sectorAutodeskAssignation) {
    return {};
  }

  const xmlData = await exportAutodeskXml({
    gantt,
    projectState,
    companySettingsState
  });

  if (!xmlData) {
    return {};
  }

  const xmlResponse = await activityService.exportToXML({
    ...exportProps,
    data: JSON.stringify([xmlData]),
    is_autodesk: sectorAutodeskAssignation || false
  });

  if (xmlResponse.isError) {
    notificationError({ message: xmlResponse.message, t });
    return;
  }

  return xmlResponse;
};
