import React from 'react';
import CompanyCell from '../../../components/CompanyCell';

export default (_, { t }) => ({
  name: 'company',
  label: t('scheduleofschedule.columns.company'),
  align: 'center',
  width: 100,
  onrender: (item) => <CompanyCell {...item.company} />
});
