import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Modal, Tooltip, message, Spin, Icon } from 'antd';
import { ReuploadIcon, ScheduleUpdateIcon, XmlIcon } from '../../icons';
import fakeAvatar from '../../assets/img/fake_user.png';
import { activityService, scheduleUpdatesService } from '../../services';
import schedulepushimage from '../../assets/img/schedulepush.png';
import moment from 'moment';
import { getSessionTokenData, getSignedUser } from '../../utils/userUtils';
import { autodeskIntegrationService } from '../../services/autodeskintegration.service';
import notificationSystemV2 from '../DesignSystem/NotificationSystemV2';
import { saveAllScheduleUpdate } from './ScheduleUpdate';
import { sentryLogger } from '../../monitor/monitor';
const stream = require('stream');

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
message.config({
  maxCount: 1
});

export const ScheduleModal = ({
  modalStyles,
  colors,
  allScheduleUpdatesModal,
  setSaveScheduleUpdatesModal,
  saveScheduleUpdatesModal,
  t,
  handleConfigClick,
  allScheduleUpdates,
  setAllScheduleUpdatesModal,
  gantt,
  sector
}) => {
  const [loadingScheduleUpdates, ,] = useState(false);
  const projectState = useSelector((state) => state.projectState);
  const companySettingsState = useSelector(
    (state) => state.companySettingsState
  );
  const [loadingSave, setLoadingSave] = useState(false);
  const sectorDateFormat = projectState.allSectors.find(
    (e) => e.id == projectState.sectorSelected
  );
  const dateFormat =
    sectorDateFormat && sectorDateFormat.dateFormat
      ? sectorDateFormat.dateFormat
      : null;
  const [sectorAutodeskAssignation, setSectorAutodeskAssignation] =
    useState(null);

  useEffect(() => {
    const fetchSectorAutodeskAssignation = async () => {
      if (!sectorAutodeskAssignation && sector) {
        const autodeskAssignation =
          await autodeskIntegrationService.getSectorAutodeskAssignation(
            sector.id
          );
        if (autodeskAssignation) {
          console.log('autodeskAssignation', autodeskAssignation);
          setSectorAutodeskAssignation(autodeskAssignation[0]);
        }
      }
    };
    fetchSectorAutodeskAssignation();
  }, [sector]);

  const saveScheduleUpdate = async () => {
    try {
      if (loadingSave) {
        return;
      }
      setLoadingSave(true);
      const { update_name, comment } = saveScheduleUpdatesModal;

      if (update_name.trim() === '') {
        message.warning(t('modals.gantt.schedule_updates.non_save_update'), 2);
        return;
      }

      if (comment.trim() === '') {
        message.warning(t('modals.gantt.schedule_updates.non_save_comment'), 2);
        return;
      }

      const { xmlUrlAutodesk } = await saveAllScheduleUpdate({
        t,
        gantt,
        projectState,
        companySettingsState,
        saveScheduleUpdatesModal,
        sectorAutodeskAssignation,
        handleConfigClick
      });

      if (!xmlUrlAutodesk) {
        setSectorAutodeskAssignation(null);
      }
    } catch (error) {
      sentryLogger.error('saveScheduleUpdate', {
        error
      });
    } finally {
      setLoadingSave(false);
    }
  };

  const downloadXmlFile = (url) => {
    if (!url) return;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf'
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'XML.xml');

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const getFooterForScheduleModal = () => {
    if (loadingScheduleUpdates) {
      return (
        <div style={{ justifyContent: 'center', width: '100%' }}>
          <Spin indicator={antIcon} />
        </div>
      );
    }

    return [
      <button
        key="cancel"
        onClick={() => {
          handleConfigClick('update_schedule_updates');
          handleConfigClick('show_schedule_updates');
        }}>
        {t('cancel')}
      </button>,
      <button
        key="save"
        data-type="main"
        style={{ cursor: loadingSave ? 'not-allowed' : 'pointer' }}
        onClick={async () => {
          await saveScheduleUpdate();
        }}>
        {loadingSave ? (
          <Spin
            style={{ maxHeight: '20px' }}
            className="loader-spinner-lookahead-table"
            indicator={antIcon}
          />
        ) : (
          t('modals.gantt.schedule_updates.new_schedule')
        )}
      </button>
    ];
  };
  return (
    <div>
      {/* List of all schedules updates */}
      <Modal
        width={800}
        wrapClassName={`activity-modification-style ${modalStyles['facelifted-modal']} ${modalStyles['saved-baselines-modal']}`}
        title={
          <>
            <ScheduleUpdateIcon color={colors.brandOrange40} />{' '}
            {allScheduleUpdatesModal.title}
          </>
        }
        visible={allScheduleUpdatesModal.visible}
        onCancel={() =>
          setAllScheduleUpdatesModal({
            allScheduleUpdatesModal,
            visible: false
          })
        }
        footer={[
          <button
            key="cancel"
            onClick={async () => {
              setAllScheduleUpdatesModal({
                ...allScheduleUpdatesModal,
                visible: false
              });
            }}>
            {t('cancel')}
          </button>,
          <button
            key="new"
            data-type="main"
            onClick={() => {
              setAllScheduleUpdatesModal({
                ...allScheduleUpdatesModal,
                visible: false
              });
              handleConfigClick('save_schedule_updates');
            }}>
            {t('modals.gantt.schedule_updates.new_schedule')}
          </button>
        ]}>
        <Row>
          <Col>
            <Row className={modalStyles['saved-baselines-modal__grid-header']}>
              <Col span={4} style={{ textAlign: 'left' }}>
                {t('modals.gantt.schedule_updates.update_name')}
              </Col>
              <Col span={9}>{t('comment')}</Col>
              <Col span={4} style={{ textAlign: 'center' }}>
                {t('modals.gantt.schedule_updates.updated_by')}
              </Col>
              <Col span={4} style={{ textAlign: 'center' }}>
                {t('date')}
              </Col>
              <Col span={3} />
            </Row>
            <div className={modalStyles['saved-baselines-modal__grid-content']}>
              {allScheduleUpdates?.map((update, i) => (
                <Row key={i}>
                  <Col
                    span={4}
                    className={modalStyles['saved-baselines-modal__text-cell']}>
                    <Tooltip placement="top" title={update.name}>
                      {update.name?.trim() || '-'}
                    </Tooltip>
                    {update.has_procore ? (
                      <img
                        style={{ position: 'absolute', marginTop: '12px' }}
                        width={12}
                        src={schedulepushimage}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col
                    span={9}
                    className={modalStyles['saved-baselines-modal__text-cell']}>
                    <Tooltip placement="top" title={update.comment}>
                      {update.comment?.trim() || '-'}
                    </Tooltip>
                  </Col>
                  <Col
                    span={4}
                    className={
                      modalStyles['saved-baselines-modal__avatar-cell']
                    }
                    style={{ textAlign: 'center' }}>
                    {update.user?.id ? (
                      <Tooltip
                        placement="top"
                        title={update.user.name + ' ' + update.user.lastname}>
                        <img src={update.user.image || fakeAvatar} />
                      </Tooltip>
                    ) : (
                      '-'
                    )}
                  </Col>
                  <Col
                    span={4}
                    style={{ textAlign: 'center', paddingLeft: 10 }}>
                    {moment(update.updatedAt).format(dateFormat)}
                  </Col>
                  <Col
                    onClick={() => downloadXmlFile(update.xmlUrl)}
                    span={3}
                    className={
                      modalStyles['saved-baselines-modal__actions-cell']
                    }
                    style={{ textAlign: 'center', cursor: 'pointer' }}>
                    <XmlIcon color={colors.white} />
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
        </Row>
      </Modal>
      {/*  new update modal */}
      <Modal
        width={500}
        wrapClassName={`activity-modification-style ${modalStyles['facelifted-modal']} ${modalStyles['new-baseline-modal']}`}
        title={
          <>
            <ReuploadIcon color={colors.brandOrange40} />{' '}
            {saveScheduleUpdatesModal.title}
          </>
        }
        visible={saveScheduleUpdatesModal.visible}
        onCancel={() =>
          setSaveScheduleUpdatesModal({
            ...saveScheduleUpdatesModal,
            visible: false
          })
        }
        footer={getFooterForScheduleModal()}>
        <div className={modalStyles['new-baseline-modal__section']}>
          <h3 style={{ textAlign: 'center' }}>
            The current schedule will be saved as a Schedule Update.
            <br />
            Please add a comment to update
          </h3>

          <label
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              alignItems: 'center'
            }}>
            <span>{t('modals.gantt.schedule_updates.update_name')}</span>
            <input
              type="text"
              style={{ width: '310px' }}
              value={saveScheduleUpdatesModal.update_name}
              onChange={({ target: { value } }) => {
                setSaveScheduleUpdatesModal({
                  ...saveScheduleUpdatesModal,
                  update_name: value
                });
              }}
            />
          </label>
          <label
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              alignItems: 'center'
            }}>
            {t('new_baseline_modal.comment')}
            <textarea
              style={{
                width: '310px',
                marginLeft: '15px'
              }}
              value={saveScheduleUpdatesModal.comment}
              onChange={({ target: { value } }) => {
                setSaveScheduleUpdatesModal({
                  ...saveScheduleUpdatesModal,
                  comment: value
                });
              }}
            />
          </label>
        </div>
      </Modal>
    </div>
  );
};
