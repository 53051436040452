import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
  Modal,
  Icon,
  Button,
  Select,
  Spin,
  Row,
  Col,
  Input,
  Upload
} from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './index.css';
import { useSelector } from 'react-redux';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';
import { Colors } from '../../../constants/colors.constants.js';
import { TagIcon } from '../../../icons/Icons';

// services
import { projectService } from '../../../services/project.service';
import closeIcon from '../../../assets/img/gantt/close-links-modal.png';

/** import library for antd upload crop */
import ImgCrop from 'antd-img-crop';

import {
  openNotification,
  checkAllFieldsValid,
  reduceFormValues,
  headersSendForm,
  getBase64,
  ObjectLogoSvg
} from '../../../utils';

/** PNG to put on users without image */
import defaultImg from '../../../assets/img/defaulSubcontract.png';

import styled from 'styled-components';
import { tagService } from '../../../services/tag.service';

const { confirm } = Modal;

function ModalAddSubContract({
  Visible,
  setVisible,
  setReloadTableUsers = (_) => {},
  ReloadTableUsers,
  modCompany = false,
  t
}) {
  // GET ID PROJECT FROM REDUX
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;
  const formRef = useRef(null);

  // GET CURRENT COMPANY FOR REDUX
  const stateCompany = useSelector((state) => state.companyState);

  const txtFieldState = {
    value: '',
    valid: true,
    typeMismatch: false,
    errMsg: ''
  };

  const createSubContract = async (data) => {
    const resp = await tagService.create(data);
    return resp;
  };

  // validation map form
  const stateTemplate = {
    namesub: {
      ...txtFieldState,
      fieldName: 'namesub',
      required: true,
      requiredTxt: t('confirmation.name_req'),
      formatErrorTxt: ''
    },
    allFieldsValid: false,
    isLoading: false
  };

  const templateTags = {
    displayColorPicker: false,
    color: {
      r: '35',
      g: '108',
      b: '242',
      a: '1'
    },
    hex: '#236CF2'
  };

  const [stateColor, setStateColor] = useState(templateTags); // state
  const [state, setState] = useState(stateTemplate); // state
  const [subcontracts, setSubcontracts] = useState([]); // state

  const resetForm = () => {
    setState(stateTemplate);
    setVisible(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!formRef.current) return;
    const form = formRef.current;
    const formValues = reduceFormValues(form.elements, state);
    const allFieldsValid = checkAllFieldsValid(formValues);

    const existName = subcontracts.some(
      (el) =>
        el.name.toLowerCase().trim() ===
        formValues.namesub.value.toLowerCase().trim()
    );

    if (existName) {
      const warningSubContractName = {
        title: t('tag_label'),
        description: t('warning_name'),
        type: 'warning'
      };
      openNotification(warningSubContractName);
      return false;
    }
    setLoading(true);
    setState(() => ({ ...state, ...formValues, allFieldsValid }));

    if (allFieldsValid) {
      setState({
        ...state,
        isLoading: true
      });
      setReloadTableUsers(false);
      const data = {
        name: state.namesub.value,
        projectId: projectSelectedId,
        description: stateColor.hex
      };

      const resp = await createSubContract(data);
      if (resp) {
        const succesSubContract = {
          title: t('tag_label'),
          description: t('success_tag'),
          type: 'success'
        };
        openNotification(succesSubContract);
        setReloadTableUsers(true);
      }
      resetForm();
      setState({
        ...state,
        isLoading: false
      });
    } else {
      const { namesub } = formValues;
      const succesSubContract = {
        title: t('tag_label'),
        description: namesub.requiredTxt,
        type: 'error'
      };
      if (!namesub.valid) {
        succesSubContract.description = namesub.requiredTxt;
      }
      openNotification(succesSubContract);
    }
    setLoading(false);
  };

  // Close modal
  const handleCancel = () => {
    setVisible(false);
  };
  // Save emails
  const handleOk = () => {
    setVisible(false);
  };

  const [ProjectSelected, setProjectSelected] = useState({});

  // GET PROJECT GENERAL SELECTED BY USER
  useEffect(() => {
    if (Visible) {
      setState(stateTemplate);
      const abortController = new AbortController();
      projectService
        .show(projectSelectedId)
        .then((response) => {
          if (response.project === undefined) {
            setProjectSelected({});
          } else {
            setProjectSelected(response.project);
          }
        })
        .catch(() => setProjectSelected({}));
      return function cleanup() {
        abortController.abort();
      };
    }
  }, [Visible]);

  useEffect(() => {
    if (Visible) getAllSubconttracts();
  }, [Visible]);

  const getAllSubconttracts = async () => {
    const tags = await tagService.showByProject(projectSelectedId);
    if (tags) {
      setSubcontracts(tags.tag);
    }
  };

  // messages
  const [Loading, setLoading] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const handleClick = () => {
    const newState = {
      ...stateColor,
      displayColorPicker: !stateColor.displayColorPicker
    };
    setStateColor(newState);
  };

  const handleChange = (color) => {
    const newState = {
      ...stateColor,
      color: color.rgb,
      hex: color.hex
    };
    setStateColor(newState);
  };

  const handleClose = () => {
    const newState = {
      ...stateColor,
      displayColorPicker: false
    };
    setStateColor(newState);
  };

  return (
    <Fragment>
      <Modal
        className="frmModalAdd custom-tag-modal"
        visible={Visible}
        closable={false}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        maskClosable={false}
        width={350}
        footer={
          Loading ? (
            <div style={{ textAlign: 'center' }}>
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <CenterButtons>
              <div className="center-btn-modal-company right-add-tag-center">
                <button className="btn-cancel-tag" onClick={() => resetForm()}>
                  {t('user_config.cancel_btn')}
                </button>
                <button className="btn-add-tag" onClick={(e) => onSubmit(e)}>
                  {t('user_config.add_btn')}
                </button>
              </div>
            </CenterButtons>
          )
        }>
        <div className="wrapp-sub-contract">
          <div className="title-frm-add">
            <span>
              <TagIcon color={Colors.PRIMARY} />
            </span>
            <span style={{ marginLeft: 10, fontSize: 16 }}>{t('new_tag')}</span>
            <div className="title-close" onClick={() => setVisible(false)}>
              <img width={14} src={closeIcon} />
            </div>
          </div>
          <div>
            <form
              ref={formRef}
              id="frm-subcontracts"
              className="frmWide"
              onSubmit={onSubmit}
              noValidate>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <span
                        style={{
                          color: 'white',
                          fontSize: 12,
                          marginRight: 12
                        }}>
                        {t('tag_name')}
                      </span>
                      <span>
                        <Input
                          style={{
                            width: 160
                          }}
                          autoComplete="off"
                          type="text"
                          name="namesub"
                          className="input-add-subcontract"
                          placeholder={''}
                          value={state.namesub.value}
                          onChange={(e) => {
                            /** update state with value selected */
                            setState({
                              ...state,
                              namesub: {
                                ...state.namesub,
                                value: e.target.value,
                                valid: true /** clear validation */
                              }
                            });
                          }}
                          required
                        />
                      </span>
                      <span
                        style={{
                          color: 'white',
                          fontSize: 12,
                          marginLeft: 10,
                          marginRight: 10
                        }}>
                        {t('color')}
                      </span>
                      <div
                        style={{
                          padding: '3px',
                          background: '#3E482D',
                          borderRadius: '4px',
                          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                          display: 'inline-block',
                          cursor: 'pointer',
                          position: 'relative',
                          top: 4,
                          left: 3
                        }}
                        onClick={() => handleClick()}>
                        <div
                          style={{
                            width: '36px',
                            height: '14px',
                            borderRadius: '2px',
                            background: `${stateColor.hex}`
                          }}
                        />
                      </div>
                      {stateColor && stateColor.displayColorPicker ? (
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: '2'
                          }}>
                          <div
                            style={{
                              position: 'fixed',
                              top: '0px',
                              right: '0px',
                              bottom: '0px',
                              left: '0px'
                            }}
                            onClick={() => handleClose()}
                          />
                          <ChromePicker
                            className="picker-wrapp"
                            color={stateColor.color}
                            onChange={(color) => handleChange(color)}
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default ModalAddSubContract;

const CenterButtons = styled.div`
  .center-btn-modal-company {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const ButtonSubcontractStyle = styled.div`
  .btn-continue-user-company {
    margin-top: 40px;
    text-align: center;
    user-select: none;
    padding: 0.15rem 0.1rem;
    line-height: 1.5;
    display: inline-block;
    border: 0px;
    cursor: pointer;
    color: #121212;
    width: 80px;
    font-size: 12px;
    height: 28px;
    border-radius: 5px;
    background-color: #7dff8a;
    transition: 0.3s;
    margin: 10px;
    border: none;
    outline: none;
  }
`;
