import { base } from '../../../../../services/base';

export const CONSTRAINT_TYPE_MAP = {
  alap: 1,
  asap: 0,
  fnet: 6,
  fnlt: 7,
  mfo: 3,
  mso: 2,
  snet: 4,
  snlt: 5
};

export const FORMAT_DATE = 'DD-MM-YYYY H:mm';
export const exportableServerURL = base.exportableUrl;
