import { calendarService } from '../../../../../services';
import {
  getCurrentSectorState,
  getSignedUser
} from '../../../../../utils/userUtils';
import { autodeskParseData } from './autodeskParseData';
import { exportableServerURL } from './constants';
import { getTaskByGanttFormatProcore, responsibleDataProcore } from './helpers';

const getCalendars = async (sectorId) => {
  const calendars = await calendarService.showBySector(sectorId);
  return calendars.calendar ? calendars.calendar : [];
};

export const exportAutodeskXml = async ({
  gantt,
  projectState,
  companySettingsState
}) => {
  let html_text = '';
  const user = getSignedUser();
  const sector = await getCurrentSectorState(projectState);
  const calendars = await getCalendars(sector.id);

  const { responsibles, assignments } = responsibleDataProcore(gantt);
  const tasksByGantt = getTaskByGanttFormatProcore(gantt);

  let propsExportAutodesk = {
    skip_circular_links: false,
    name: `autodesk_${sector.name}`,
    tasks: autodeskParseData(gantt),
    project: {
      Author: user.email,
      MinutesPerDay: function () {
        return sector.hoursPerDay * 60;
      }
    },
    server: exportableServerURL,
    isProcore: true,
    calendars,
    tasksByGantt
  };
  const { transferResponsibles } = companySettingsState;
  const isTransferResponsible = transferResponsibles?.transferResponsible;

  if (isTransferResponsible) {
    propsExportAutodesk = {
      ...propsExportAutodesk,
      responsibles,
      assignments
    };
  }

  html_text = gantt.exportToMSProjectProcore(propsExportAutodesk);
  return html_text;
};
